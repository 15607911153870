<template>
  <div class="flex flex-row items-center justify-between relative">
    <div class="flex-grow sm:text-center text-left">
      <h1 class="text-3xl text-blue-200 inline-block sm:text-4xl">Syndles</h1>
    </div>
    <div class="flex space-x-2 sm:space-x-4 text-2xl text-blue-200 items-center absolute right-0">

      <div v-if="foundWords.length > 0">
        <Dictionary :words="foundWords"/>
      </div>
      <font-awesome-icon class="cursor-pointer hover:text-blue-400" :icon="['fas', 'question']"
                         @click="showHelp = !showHelp"/>
      <font-awesome-icon class="cursor-pointer hover:text-blue-400" :icon="['fas', 'chart-simple']"
                         @click="showLeaderboard = !showLeaderboard"/>
      <font-awesome-icon class="cursor-pointer hover:text-blue-400" :icon="['fas', 'cog']"
                         @click="showSettings = !showSettings"/>
      <div>
        <button v-if="!isAuthenticated && !isLoading"
                class="text-xl cursor-pointer bg-gradient-to-r from-green-400 to-teal-400 text-gray-900" @click="login">
          Login
        </button>
      </div>
    </div>
  </div>

  <Modal :is-visible="showLeaderboard" @update:isVisible="showLeaderboard = $event" heading="Stats">
    <div v-if="isAuthenticated">
      <Stats/>
    </div>
    <div v-else>
      <div class="flex flex-col items-center">
        <img src="/radial-icon.png" alt="logo for syndles" class="w-32 m-4"/>
        <h2 class="text-2xl">Here to see your stats?</h2>
        <button @click="loginWithRedirect()"
            class="bg-gradient-to-r from-green-400 to-teal-400 hover:bg-green-500 text-xl text-gray-900 w-60 py-2 mt-4">
          Create a free account
        </button>
          <a class="text-white underline hover:cursor-pointer mt-4" @click="loginWithRedirect()">Already registered? Login</a>
      </div>
    </div>
  </Modal>
  <Modal :is-visible="showHelp" @update:isVisible="showHelp = $event" heading="How to play">
    <GameDescription/>
  </Modal>
  <Modal :is-visible="showSettings" @update:isVisible="showSettings = $event" heading="Settings">
    <Settings @saved="showSettings = false"/>
  </Modal>
  <Modal :is-visible="showInstallPwa" @update:isVisible="showInstallPwa = $event" heading="Install App">
    <InstallPwa/>
  </Modal>
</template>
<script setup>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Modal from "./Modal.vue";
import Leaderboard from "./Leaderboard.vue";
import {computed, ref} from "vue";
import Dictionary from "./Dictionary.vue";
import {useGrid} from "../composables/useGrid.js";
import GameDescription from "./GameDescription.vue";
import Settings from "./Settings.vue";
import InstallPwa from "./InstallPwa.vue";

const showLeaderboard = ref(false);
const showHelp = ref(false);
const showSettings = ref(false);
const showInstallPwa = ref(false);


const {foundWords} = useGrid();

import {useAuth0} from "@auth0/auth0-vue";
import Stats from "./Stats.vue";

const {loginWithRedirect, isAuthenticated, logout, user, isLoading} = useAuth0();

async function login() {
  await loginWithRedirect();
}

function canInstall() {
  return !window.matchMedia('(display-mode: standalone)').matches;
}
</script>


<style scoped>

</style>