import {nextTick, ref} from 'vue';

let loading = ref(false);

import {useAuth0} from "@auth0/auth0-vue";


const syndleUser = ref({});

export const useUserState = () => {

    const {isAuthenticated, user, isLoading, getAccessTokenSilently} = useAuth0();
    async function createUser() {
        let accessToken = '';
        try {
            accessToken = await getAccessTokenSilently()
        } catch(e) {
            console.log('User is not logged in');
        }

        let userId = localStorage.getItem('userId');

        if (!userId) {
            userId = uuidv4();
        }
        const name = localStorage.getItem('name')

        let auth0Id;
        if (isAuthenticated.value) {
            auth0Id = user.value.sub;
        }

        const syndlesUser = {
            id: userId,
            name: name,
            auth0Id: auth0Id
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(syndlesUser)
        };

        let createUserResponse;

        loading.value = true;
        if (accessToken) {
           requestOptions.headers['Authorization']  = `Bearer ${accessToken}`;
            createUserResponse = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/authed-users`, requestOptions);
        } else {
            createUserResponse = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users`, requestOptions);
        }

        loading.value = false;

        if (!createUserResponse.ok) {
            console.error("There was an error saving user");
            return;
        }
        const json = await createUserResponse.json();
        syndleUser.value = {...json};
        localStorage.setItem('userId', json.userId);
        localStorage.setItem('name', json.name);
        return json;
    }


    async function updateUser() {
        const userId = localStorage.getItem("userId");
        const name = localStorage.getItem("name");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    name: name,
                }
            )
        };

        loading.value = true;
        const createUserResponse = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}`, requestOptions);
        loading.value = false;
        if (!createUserResponse.ok) {
            console.error("There was an error saving user");
            return await createUserResponse.json();
        }

        const json = await createUserResponse.json();
        syndleUser.value = {...json};
        localStorage.setItem('userId', json.userId);
        localStorage.setItem('name', json.name);
    }

    async function getLoggedInUser() {

        await getAccessTokenSilently();
        let userId = user.value.sub;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        loading.value = true;
        const createUserResponse = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}`, requestOptions);
        loading.value = false;

        if (!createUserResponse.ok) {
            console.error("There was an error saving user");
            return;
        }
        const json = await createUserResponse.json();
        return json;
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    return {
        createUser,
        updateUser,
        getLoggedInUser,
        loading,
        syndleUser
    }
}
