<template>
  <div>
    <div id="game" class="game flex flex-col items-center justify-center">
      <div class="flex flex-row mt-8 mt-2-on-short-screen mb-2 sm:mb-6 text-2xl text-green-400">
        <Trophy :is-score-popping="isScorePopping" :score="score"/>
      </div>


      <div v-for="row in grid" class="row flex flex-row items-center">
        <div v-for="tile in row"
             class="tile w-9 h-9 w-8-on-short-screen h-8-on-short-screen border-blue-200 border-2 rounded pt-0.5 m-1 text-xl"
             :class="{invisible: !tile.letter, 'pop-animation': isLetterPopping && correctGuesses[correctGuesses.length - 1] === tile.letter}">
          {{ tile.found ? tile.letter : '' }}
        </div>
      </div>

      <div class="flex flex-row mb-2 sm:mb-6 mt-2">
        <TransitionGroup name="hearts">
          <div v-for="normal in heartCount" :key="normal" class="text-2xl p-0.5 text-red-600">
            <Heart/>
          </div>
        </TransitionGroup>
      </div>
      <div class="flex row" v-if="puzzleFinished">
        <div v-if="!failedGame">
          <div class="p-2 pb-6">Nice job! 🥳 Get the next one!</div>
          <button class="bg-gradient-to-r from-green-400 to-teal-400 hover:bg-green-500 text-xl text-gray-900 w-60 py-2"
                  :disabled="loading" @click="nextGame()">Next Game
          </button>
        </div>
        <GameOver v-if="failedGame" :score="score"></GameOver>
      </div>
      <LoadingSpinner v-if="loading"></LoadingSpinner>

      <div v-if="!puzzleFinished" v-for="alphabet in alphabetRows" class="row flex flex-row">
        <button v-for="letter in alphabet"
                class="tile w-7 h-12 p-0 bg-gray-600 m-1 text-xl rounded cursor-pointer select-none text-center border-0"
                :class="{ 'bg-gradient-to-t from-pink-600 to-red-600' : isLetterAWrongGuess(letter), 'bg-gradient-to-t from-teal-400 to-green-400': isLetterACorrectGuess(letter)}"
                @click="letterClicked(letter)">
          {{ letter }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, watch, computed} from 'vue'
import {useGameEngine} from "../composables/useGameEngine.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import GameOver from "./GameOver.vue";
import Heart from "./icons/Heart.vue";
import Trophy from "./icons/Trophy.vue";

const {
  wrongGuessCount,
  puzzleFinished,
  failedGame,
  nextGame,
  isLetterAWrongGuess,
  isLetterACorrectGuess,
  letterClicked,
  grid,
  score,
  correctGuesses,
  loading,
  initialiseGame,
} = useGameEngine();

defineProps({
  msg: String,
})

let isScorePopping = ref(false);
let isLetterPopping = ref(false);

let firstAlphabetRow = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
let secondAlphabetRow = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
let thirdAlphabetRow = ["Z", "X", "C", "V", "B", "N", "M"];
let alphabetRows = [firstAlphabetRow, secondAlphabetRow, thirdAlphabetRow];
onMounted(() => initialiseGame());

watch(score, (newValue, oldValue) => {
  isScorePopping.value = true;
  setTimeout(() => isScorePopping.value = false, 500);
});

const heartCount = computed(() => Math.max(0, 6 - wrongGuessCount.value));


watch(correctGuesses, (newValue, oldValue) => {
  if (newValue.length > 1 && oldValue.length === 0) {
    return; // edge case when you refresh screen
  }
  if (newValue.length > oldValue.length) {
    isLetterPopping.value = true;
    setTimeout(() => isLetterPopping.value = false, 500);
  }
});

</script>


<style scoped>
.hearts-move,
.hearts-enter-active,
.hearts-leave-active {
  transition: all 0.5s ease;
}

.hearts-enter-from,
.hearts-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.hearts-leave-active {
  position: absolute;
}


@media screen and (max-height: 700px) {
  .h-8-on-short-screen {
    height: 2rem;
  }

  .w-8-on-short-screen {
    width: 2rem;
  }

  .mt-2-on-short-screen {
    margin-top: 0.5rem;
  }
}
</style>
