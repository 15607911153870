import {computed, ref} from "vue";

const gridSize = 7;
let grid = ref([])
let words = ref([]);
let foundWords = ref([]);
export const useGrid = () => {

    const populateGrid = (newWords) => {
        emptyGrid();
        foundWords.value = [];
        words.value = newWords;
        let placedSecondWord = false;
        let placedThirdWord = false;

        for (let firstWordIndex = 0; firstWordIndex < words.value.length; firstWordIndex++) {
            [words.value[0], words.value[firstWordIndex]] = [words.value[firstWordIndex], words.value[0]];
            emptyGrid();
            for (let i = 0; i < gridSize; i++) {
                placeFirstWord(words.value, i);
                placedSecondWord = placeWord(words.value[1]);
                placedThirdWord = placeWord(words.value[2]);
                if (placedSecondWord && placedThirdWord) {
                    return true;
                }
                emptyGrid();
            }
            [words.value[firstWordIndex], words.value[0]] = [words.value[0], words.value[firstWordIndex]];
        }

        return false
    }

    function emptyGrid() {
        for (let i = 0; i < gridSize; i++) {
            grid.value[i] = [];
            for (let j = 0; j < gridSize; j++) {
                grid.value[i][j] = {
                    letter: undefined,
                    words: [],
                    found: false,
                }
            }
        }
    }

    function placeFirstWord(words, column) {
        let firstWord = words[0];
        let yOffset = 0;
        if (firstWord.length < gridSize) {
            yOffset = Math.floor((gridSize - firstWord.length) / 2);
        }
        for (let i = 0; i < firstWord.length; i++) {
            grid.value[i + yOffset][column].letter = firstWord[i];
            grid.value[i + yOffset][column].words.push(firstWord);
        }
    }

    function placeWord(word) {
        let intersections = getIntersections(word, "horizontal");
        for (let intersection of intersections) {
            const letter = grid.value[intersection.y][intersection.x].letter;
            const indexOfLetter = word.indexOf(letter);

            const lengthAfter = word.length - indexOfLetter;
            const lengthBefore = indexOfLetter;
            if (intersection.x + lengthAfter > gridSize) {
                continue;
            } else if (intersection.x - lengthBefore < 0) {
                continue;
            }

            let placementIsValid = true;
            for (let i = -lengthBefore; i < lengthAfter; i++) {
                if (i === 0) continue;
                if (intersection.y > 0 && grid.value[intersection.y - 1][intersection.x + i].letter) {
                    placementIsValid = false;
                    break;
                }
                if (intersection.y < gridSize - 1 && grid.value[intersection.y + 1][intersection.x + i].letter) {
                    placementIsValid = false;
                    break;
                }

            }

            if (!placementIsValid) continue;

            for (let i = 0; i < lengthAfter; i++) {
                grid.value[intersection.y][intersection.x + i].letter = word[indexOfLetter + i];
                grid.value[intersection.y][intersection.x + i].words.push(word);

            }

            for (let i = 0; i <= lengthBefore; i++) {
                grid.value[intersection.y][intersection.x - i].letter = word[indexOfLetter - i];
                grid.value[intersection.y][intersection.x - i].words.push(word);
            }
            return true;
        }
        return false;
    }

    function getRowWithFirstLetter() {
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                if (grid.value[i][j].letter) {
                    return i;
                }
            }
        }
    }

    function formatGrid() {
        let pullUp = getRowWithFirstLetter();
        if (pullUp === 0) return;
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                if (grid.value[i][j].letter) {
                    grid.value[i - pullUp][j].letter = grid.value[i][j].letter;
                    grid.value[i - pullUp][j].words = grid.value[i][j].words;
                    grid.value[i][j].letter = undefined;
                    grid.value[i][j].words = [];
                }
            }
        }
    }

    const getIntersections = (word, direction) => {
        let intersections = [];
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                for (const letter of word) {
                    if (grid.value[i][j].letter === letter) {
                        if (direction === "vertical" && (i === gridSize - 1 || !grid.value[i + 1][j].letter) && (i === 0 || !grid.value[i - 1][j].letter)) {
                            intersections.push({
                                x: j,
                                y: i
                            })
                        } else if (direction === "horizontal" && (j === gridSize - 1 || !grid.value[i][j + 1].letter) && (j === 0 || !grid.value[i][j - 1].letter)) {
                            intersections.push({
                                x: j,
                                y: i
                            })
                        }
                    }
                }
            }
        }
        return intersections;
    }


    function checkAnyWordsFound() {
        for (let word of words.value) {
            let length = 0;
            for (let i = 0; i < gridSize; i++) {
                for (let j = 0; j < gridSize; j++) {
                    if (grid.value[i][j].words.includes(word) && grid.value[i][j].found) {
                        length++;
                    }
                }
            }
            if (length === word.length) {
                if (!foundWords.value.includes(word)) {
                    foundWords.value.push(word);
                }
            }
        }
    }

    function selectedLetter(letter) {
        let foundLetter = false;
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                if (grid.value[i][j].letter === letter) {
                    grid.value[i][j].found = true;
                    foundLetter = true;
                }
            }
        }
        checkAnyWordsFound();
        return foundLetter;
    }

    function revealWords() {
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                grid.value[i][j].found = true;
            }
        }
        checkAnyWordsFound();
    }

    return {
        selectedLetter,
        populateGrid,
        grid,
        revealWords,
        foundWords
    }
}