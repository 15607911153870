<template>
  <div class="flex justify-center items-center">
    <div class="justify-center items-center w-full max-w-96">
      <div class="flex flex-col items-center">
        <img src="/radial-icon.png" alt="logo for syndles" class="w-32 m-4"/>
        <h2 class="text-xl mb-4">How good is your vocabulary?</h2>
        <input @keyup.enter="submitName" v-model="name" autofocus type="text" placeholder="Enter username"
               class="p-2 border-2 border-blue-200 rounded w-full">
        <p v-if="showLengthError" class="text-red-300">Must be more than 2 letters</p>
        <p v-if="submitError" class="text-red-300">{{submitError}}</p>
        <div class="flex flex-row w-full gap-2">
          <button @click="submitName"
                  class="bg-gradient-to-r from-green-400 to-teal-400 hover:bg-green-500 py-2 px-4 w-full mt-1 text-gray-900 text-xl">
            Play
          </button>
        </div>
      </div>
      <GameDescription class="mt-4"/>
      <p class="text-center mt-6">© 2024 Garrigan</p>
    </div>
  </div>
</template>
<script setup>

import GameDescription from "./GameDescription.vue";
import {useName} from "../composables/useName.js";
import {useUserState} from "../composables/useUserState.js";

const { isNameValid, name, showLengthError, submitError} = useName();

const { updateUser } = useUserState();
const emit = defineEmits(['enteredName'])

async function submitName() {
  if (isNameValid.value) {
    localStorage.setItem('name', name.value.trim());

    submitError.value = await updateUser(name.value.trim());
    emit('enteredName');
  }
}
</script>


<style scoped>

</style>