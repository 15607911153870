import { createRouter, createWebHistory} from 'vue-router'

import HomeView from "./views/HomeView.vue";
import CallbackView from "./views/CallbackView.vue";

const routes = [
    { path: '/callback', component: CallbackView },
    { path: '/', component: HomeView },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router