<template>
  <div class="flex flex-col">
    <Heading/>
    <Game v-if="syndleUser.name"/>
    <Landing v-else-if="syndleUser.userId"/>
    <loading-spinner v-else/>
  </div>
</template>
<script setup>
import Heading from "./Heading.vue";
import Game from "./Game.vue";
import {onMounted, ref, watch} from "vue";
import Landing from "./Landing.vue";
import {useAuth0} from "@auth0/auth0-vue";
import {useUserState} from "../composables/useUserState.js";
import LoadingSpinner from "./LoadingSpinner.vue";

const {isAuthenticated, getAccessTokenSilently} = useAuth0();

const {createUser, syndleUser} = useUserState();

let hasName = ref(!!localStorage.getItem('name'));

onMounted(async () => {
  // const isCallback = isAuthCallback();
  // todo might be able to add this conditional back in if (isCallback) got rid because I thought there was an issue with it
  const user = await createUser();
  hasName.value = !!user.name;
});

// watch(isAuthenticated, async () => {
//   if (isAuthenticated.value) {
//     await getAccessTokenSilently();
//     const user = await createUser();
//     hasName.value = !!user.name;
//   }
// })

function isAuthCallback() {
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const params = new URLSearchParams(parsedUrl.search);
  const code = params.get('code');
  const state = params.get('state');
  return (code || state);
}
</script>
