import {ref} from 'vue';
import {useAuth0} from "@auth0/auth0-vue";


export const useStats = () => {
    const {isAuthenticated, user, isLoading, getAccessTokenSilently} = useAuth0();
    let loading = ref(false);
    let stats = ref([]);
    async function getStats() {
        loading.value = true;
        let userId = localStorage.getItem('userId');

        let accessToken = '';
        try {
            accessToken = await getAccessTokenSilently()
        } catch (e) {
            console.log('User is not logged in');
        }
        let auth0Id;
        if (isAuthenticated.value) {
            auth0Id = user.value.sub;
        }
        const syndlesUser = {
            id: userId,
            auth0Id: auth0Id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(syndlesUser)
        };
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/authed-users/stats`, requestOptions);
        loading.value = false;
        if (response.ok) {
            stats.value = await response.json();
        } else {
            console.error("Failed to fetch stats", response.statusText);
        }
    }

    return {
        getStats,
        loading,
        stats
    };
}

