<template>
  <div class="text-left">
    <div class="mt-10 justify-center items-center w-full max-w-96">
      <div class="flex flex-col">
        <button v-if="showInstallPwa" @click="installPwa"
                class="bg-blue-400 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded w-full mt-1 text-xl">
          Install as App
        </button>

        <div v-if="showAppleUserPrompt">
          <h3 class="text-xl">Install Syndles App</h3>
          1. Tap the share icon
          <img src="/share.png" alt="share icon for apple users"/>
          2. Tap the "Add to Home Screen" option
          <img src="/homescreen.png" alt="add to homescreen image for apple users"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
const showInstallPwa = ref(false);
const showAppleUserPrompt = ref(false);
let deferredPrompt;
const emit = defineEmits(['saved'])


onMounted(() => {
  if (/iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)) {
    showAppleUserPrompt.value = true;
  }

  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('Before install event fire');
    e.preventDefault();
    deferredPrompt = e;
    showInstallPwa.value = true;
  });
})

function installPwa() {
  deferredPrompt.prompt();
}
</script>


<style scoped>

</style>