<template>
  <div class="text-left">
    <div class="mt-10 justify-center items-center w-full max-w-96">
      <div class="flex flex-col">
        <label for="enterName" class="mt-4">Change username</label>
        <input id="enterName" @keyup.enter="submitName" v-model="name" autofocus type="text"
               placeholder="Enter username"
               class="p-2 border-2 border-blue-200 rounded w-full">

        <p v-if="showLettersError" class="text-red-300">Must only contain letters</p>
        <p v-if="showLengthError" class="text-red-300">Must be more than 2 letters</p>
        <p v-if="submitError" class="text-red-300">{{submitError}}</p>
        <button @click="submitName"
                class="bg-gradient-to-r from-green-400 to-teal-400 hover:bg-green-500 mt-1 text-gray-900 text-xl py-2">
          Save
        </button>

        <div class="flex flex-row items-center justify-between mt-4">
          <label for="issue">Experiencing an issue?</label>
          <a id="issue" class="text-green-400 underline" href="https://typebot.co/syndles-issue-hk0noj8">Send Issue</a>
        </div>
        <div class="flex flex-row items-center justify-between mt-4">
          <label for="idea">Got a wicked idea?</label>
          <a id="idea" class="text-green-400 underline" href="https://typebot.co/syndles-ooxwugf">Send Idea</a>
        </div>

        <button v-if="isAuthenticated" @click="signout" class="border-green-400 mt-4 text-white text-xl py-2 bg-gray-900">
          Logout
        </button>
        <div class="flex items-center justify-center mt-8 cursor-pointer" @click="openTwitter">
          <img src="/twitter.png" style="width: 30px; border-radius: 50%;">
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import {computed, onMounted, ref} from "vue";
import {useUserState} from "../composables/useUserState.js";
import {useName} from "../composables/useName.js";
import {useAuth0} from "@auth0/auth0-vue";

const emit = defineEmits(['saved'])

const { isAuthenticated, logout } = useAuth0();
const { isNameValid, name, showLengthError, showLettersError, submitError} = useName();
const {updateUser} = useUserState();

onMounted(() => {
  let storedName = localStorage.getItem('name');
  if (storedName) {
    name.value = storedName;
  }
})

async function submitName() {
    if (isNameValid.value) {
      localStorage.setItem('name', name.value.trim());
      submitError.value = await updateUser(name.value.trim());
      if (!submitError.value) {
        emit('saved');
      }
    }
}

function nameIsJustLetters() {
  for (let letter of name.value) {
    if (!alphabet.includes(letter)) {
      return false;
    }
  }
  return true;
}

function signout() {
  logout({ logoutParams: { returnTo: window.location.origin } });
  localStorage.clear();
}
function openTwitter() {
  window.open('https://x.com/SyndlesGame');
}
</script>


<style scoped>

</style>