<template>
  <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center text-white z-10" @click="handleBackdropClick">
    <div class="p-6 bg-gray-900 rounded-lg shadow-lg max-h-128 overflow-auto h-[29rem] w-96" @click.stop>
      <header class="flex justify-between items-center text-2xl">
        <h2>{{heading}}</h2>
        <button @click="closeModal" class="text-lg">&times;</button>
      </header>
      <main class="mt-4">
        <slot>Default content</slot>
      </main>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:isVisible']);
defineProps({
  isVisible: Boolean,
  heading: String
})

const handleBackdropClick = (event) => {
  if (event.target === event.currentTarget) {
    closeModal();
  }
};

const closeModal = () => {
  emit('update:isVisible', false);
};
</script>
