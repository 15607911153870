<template>
  <div class="w-full">
    <div class="flex flex-col mt-2 ">
      <div class="flex flex-row justify-between items-center mt-2 mb-4">
        <button class="cursor-pointer" @click="previous()"><</button>
        <div>
          <div class="flex flex-col flex-1">
            <p class="text-xl text-gradient font-extrabold">{{ leaderboardDate }}</p>
          </div>
        </div>
        <button class="cursor-pointer" @click="next()">></button>
      </div>
      <table>
        <thead class="border-blue-200 border-b-2 border-t-2">
        <tr class="m-4 mt-6">
          <th>
            Rank
          </th>
          <th>
            Name
          </th>
          <th>
            Score
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-if="loading">
          <td colspan="3" class="text-center">
            <LoadingSpinner></LoadingSpinner>
          </td>
        </tr>
        <tr v-else v-for="(score, index) in scores" :class="{ 'text-green-400' : index === userPosition - 1}">
          <td>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-gold-500" v-if="index === 0"/>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-silver-500" v-if="index === 1"/>
            <font-awesome-icon :icon="['fas', 'trophy']" class="text-bronze-500" v-if="index === 2"/>
            <p v-else>{{index > 2 ? index + 1 : ''}}</p>
          </td>
          <td>
            <div class="flex justify-center items-center">
              <div>
                {{ score.score === 100 ? score.name + " 🔥" : score.name}}

              </div>
              <div class="ml-2" v-if="score.verified">
                <img src="/favicon-16x16.png" title="Syndles Member"/>
              </div>
            </div>
          </td>
          <td>{{ score.score }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script setup>

import {computed, onMounted, ref} from "vue";
import {useScores} from "../composables/useScores.js";
import LoadingSpinner from "./LoadingSpinner.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const {scores, getScores, loading, userPosition} = useScores();

let numberOfDaysBack = ref(0);

onMounted(async () => {
   await getScores(numberOfDaysBack.value);
})

const leaderboardDate = computed(() => {
  if (numberOfDaysBack.value === 0) {
    return 'Today';
  } else if (numberOfDaysBack.value === 1) {
    return 'Yesterday';
  }
  let date = new Date();
  date.setDate(date.getDate() - numberOfDaysBack.value);
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let year = date.getFullYear();
  return `${day}/${month}/${year}`;
});

async function previous() {
  numberOfDaysBack.value++;
  await getScores(numberOfDaysBack.value);
}

async function next() {
  if (numberOfDaysBack.value <= 0) return;
  numberOfDaysBack.value--;
  await getScores(numberOfDaysBack.value);
}
</script>


<style scoped>

</style>