import { ref } from 'vue';
export const useDictionary = () => {

    let loading = ref(false);
    async function getDefinition(word) {
        try {
            loading.value = true;
            const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
            loading.value = false;
            if (response.ok) {
                return await response.json();
            } else {
                return '';
            }
        } catch (e) {
            return '';
        }
    }

    return { getDefinition, loading };
}
