<template>
  <div class="min-w-96">

    <div class="flex flex-col items-center">
      <div>You've completed <span class="text-green-400">{{ score }}</span> today!</div>
    </div>
    <div class="mt-2 text-lg">NEXT PUZZLE IN</div>
    <div class="text-4xl">{{hoursUntilTomorrow}}:{{minutesUntilTomorrow}}:{{secondsUntilTomorrow}}</div>

    <button class="mt-2 bg-gradient-to-r from-green-400 to-teal-400 hover:bg-green-500 text-xl text-gray-900 w-60 py-2" @click="share()">Share</button>
    <Leaderboard class="w-40"></Leaderboard>
  </div>
</template>
<script setup>

import Leaderboard from "./Leaderboard.vue";
import {ref} from "vue";

const props = defineProps({
  score: Number,
})

let hoursUntilTomorrow = ref('00');
let minutesUntilTomorrow = ref('00');
let secondsUntilTomorrow = ref('00');

setInterval(() => {
  let now = new Date();
  let nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
  let tomorrow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));

  let millisecondsUntilTomorrow = tomorrow - nowUtc;

  secondsUntilTomorrow.value = Math.floor(millisecondsUntilTomorrow / 1000);
  minutesUntilTomorrow.value = Math.floor(secondsUntilTomorrow.value / 60);
  hoursUntilTomorrow.value = Math.floor(minutesUntilTomorrow.value / 60);

  hoursUntilTomorrow.value = String(hoursUntilTomorrow.value).padStart(2, '0');
  minutesUntilTomorrow.value = String(minutesUntilTomorrow.value % 60).padStart(2, '0')
  secondsUntilTomorrow.value = String(secondsUntilTomorrow.value % 60).padStart(2, '0');

}, 1000)

function share() {
  let trophies = ''
  for (let i = 0; i < props.score; i++) {
    trophies += '🏆';
  }
  if (navigator.share) {
    navigator.share({
      title: 'Syndles',
      text: `Syndles ${formatUTCDate()}: I scored ${props.score} ${trophies} `,
      url: 'https://syndles.com'
    })
  } else {
    alert('Web Share API is not supported in your browser.');
  }
}

function formatUTCDate() {
  const date = new Date();
  const year = date.getUTCFullYear().toString().slice(-2);
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${day}/${month}/${year}`;
}
</script>


<style scoped>

</style>