import {ref} from 'vue';
import {useScores} from "./useScores.js";

export const useWords = () => {

    let currentWords = ref([]);
    let loading = ref(false);
    const {setUserPosition, scores} = useScores();

    async function getWords() {
        loading.value = true;
        const userId = localStorage.getItem("userId");
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}/words`);
        loading.value = false;
        if (response.ok) {
            let json = await response.json();
            currentWords.value = await decode(json.synonyms);
        } else {
            console.error("Failed to fetch words", response.statusText);
        }
    }

    async function completedWords() {
        loading.value = true;
        const userId = localStorage.getItem("userId");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                synonyms: currentWords.value
            })
        };
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}/words/complete`, requestOptions);
        loading.value = false;
        if (response.ok) {
            let json = await response.json();
            currentWords.value = await decode(json.synonyms);
            return json.game;
        } else {
            console.error("Failed to send completed request", response.statusText);
        }
    }

    async function over() {
        loading.value = true;
        const userId = localStorage.getItem("userId");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
            })
        };
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}/words/over`, requestOptions);
        loading.value = false;
        if (response.ok) {
            scores.value = await response.json();

            setUserPosition();
        } else {
            console.error("Failed to fetch scores", response.statusText);
        }

    }

    async function makeGuess(guesses) {
        const userId = localStorage.getItem("userId");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guesses: guesses,
            })
        };
        const response = await fetch(`${import.meta.env.VITE_APP_API_BASE_URL}/api/users/${userId}/words/guess`, requestOptions);
    }

    async function decode(synonyms) {
        // naughty, naughty, you shouldn't be here should you!
        let iv = [0x00, 0x01, 0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0x0A, 0x0B, 0x0C, 0x0D, 0x0E, 0x0F];
        let key = [0x00, 0x01, 0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0x0A, 0x0B, 0x0C, 0x0D, 0x0E, 0x0F];

        let keyBuffer = new Uint8Array(key).buffer;
        let ivBuffer = new Uint8Array(iv).buffer;

        const cryptoKey = await window.crypto.subtle.importKey(
            "raw",
            keyBuffer,
            {name: "AES-CBC"},
            false,
            ["decrypt"]
        );

        let words = [];
        for (const synonym of synonyms) {
            let binaryString = window.atob(synonym);
            let synonymAsBuffer = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                synonymAsBuffer[i] = binaryString.charCodeAt(i);
            }
            const decryptedData = await window.crypto.subtle.decrypt(
                {
                    name: "AES-CBC",
                    iv: ivBuffer,
                },
                cryptoKey,
                synonymAsBuffer
            );

            let decryptedText = new TextDecoder().decode(new Uint8Array(decryptedData));
            words.push(decryptedText);
        }
        return words;
    }

    return {currentWords, getWords, loading, completedWords, over, makeGuess};
}
