import {createApp} from 'vue'
import './style.css'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHeart,
    faStar,
    faLightbulb,
    faChartSimple,
    faQuestion,
    faBook,
    faTrophy,
    faGear
} from '@fortawesome/free-solid-svg-icons'
import {createAuth0} from "@auth0/auth0-vue";
import router from "./router.js";

library.add(faHeart)
library.add(faStar)
library.add(faLightbulb)
library.add(faChartSimple)
library.add(faQuestion)
library.add(faBook)
library.add(faTrophy)
library.add(faGear)

const app = createApp(App);

if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: "https://e28f854a52e0c42399bb5f4e4b5feca7@o4508020726956032.ingest.de.sentry.io/4508047353446480",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
app.use(router);
app.use(
    createAuth0({
        domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
        clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
        cacheLocation: 'localstorage',
        authorizationParams: {
            redirect_uri: window.location.origin + '/callback',
            audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email offline_access'
        },
        useRefreshTokens: true
    })
);

app.mount('#app');
