<template>
  <div class="flex text-2xl" v-if="words.length > 0">
    <font-awesome-icon class="cursor-pointer hover:text-blue-400" :icon="['fas', 'book']"
                       @click.stop="showDictionary = true"/>
  </div>
  <Modal :is-visible="showDictionary" @update:isVisible="showDictionary = $event" heading="Dictionary">
    <div class="flex flex-row text-xl">
      <div v-for="(word, index) in words" :key="index" class="flex flex-grow">
        <button class="cursor-pointer bg-gradient-to-r from-green-400 to-teal-400 text-gray-900" v-if="index === selectedIndex">
          {{ word[0].toUpperCase() }}{{ word.substring(1).toLowerCase() }}
        </button>
        <button class="cursor-pointer" @click.stop="changeWord(index)" v-else>
          {{ word[0].toUpperCase() }}{{ word.substring(1).toLowerCase() }}
        </button>
      </div>
    </div>


    <div v-if="definitions.length > 0" v-for="meaning of definitions[selectedIndex].meanings"
         class="flex flex-col mt-2">
      <div class="text-left text">
        {{ meaning.partOfSpeech }}
      </div>
      <ul>
        <li v-for="definition in meaning.definitions" class="text-left list-disc list-inside text-xs">
          {{ definition.definition }}
        </li>
      </ul>
    </div>
  </Modal>
</template>
<script setup>

import {onMounted, ref, watch} from "vue";
import Modal from "./Modal.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useDictionary} from "../composables/useDictionary.js";

let showDictionary = ref(false);
const {getDefinition, loading} = useDictionary();

let definitions = ref([]);
let props = defineProps({
  words: Array
})
let selectedIndex = ref(0);

onMounted(async () => {
  for (let i = 0; i < props.words.length; i++){
    let word = props.words[i];
    try {
      addFallbackDefinition(word);
      const definition = await getDefinition(word);
      if (definition && definition.length > 0) {
        definitions.value[i] = definition[0];
      }
    } catch (error) {
      console.error('Error fetching definition for ', word, error);
    }
  }
})

async function changeWord(index) {
  if (index > definitions.value.length - 1) {
    addFallbackDefinition(props.words[index]);
    const definition = await getDefinition(props.words[index]);
    if (definition && definition.length > 0) {
      definitions.value[index] = definition[0];
    }
  }
  selectedIndex.value = index;
}

function addFallbackDefinition(word) {
  definitions.value.push({
    meanings: [{
      partOfSpeech: 'There was an issue',
      definitions: [{definition: `There was an error fetching definition for ${word.toLowerCase()}`}]
    }]
  })
}

</script>


<style scoped>

</style>