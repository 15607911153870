import {computed, ref} from 'vue';

export const useName = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const showLengthError = ref(false);
    const submitError = ref('')
    const name = ref('');

    const isNameValid = computed(() =>  {
        if (name.value.length <= 2) {
            showLengthError.value = true;
            return false;
        }

        showLengthError.value = false;
        return true;
    });

    return {isNameValid, name, showLengthError, submitError};
}
